import { JSX } from 'solid-js';

export interface MenuItemType {
    actionType?: "add" | "edit" | "delete" | "save" | "select" | "other"
    value?: string;
    label?: string;
    itemType?: "route" | "divider" | "switch" | "action" 
    icon?: "add" | "edit" | "delete" | "save" | "select"
    routeName?: string;
    routeParamName?: string;
    routeParam?: string;
    handleMenuClick?: () => void;
    //handleMenuClick?: (value: string) => void;
    // setLabel?: (value: string) => void;
};


export interface FilterListMenuProps {
    options: MenuItemType[]; 
    handleEvent:  () => void;
    children?: JSX.Element;
}

export const candidateCVMenu: MenuItemType[] = [
    
    { value: "activate", label: "Use AI to show customized CV resume", itemType: "switch", routeName: "" },
    { value: "activate", label: "Auto create industries based on jobs", itemType: "switch", routeName: "" },
    { value: "activate", label: "Transparent dialog with customers", itemType: "switch", routeName: "" },
    { value: "activate", label: "Active feedback contribution", itemType: "switch", routeName: "" },
    { value: "activate", label: "Receive notifications for nearby social events", itemType: "switch", routeName: "" },
    { value: "activate", label: "Receive notifications for nearby professional events", itemType: "switch", routeName: "" },
];
export const customerCandidateMenu: MenuItemType[] = [
    { value: "selectcandidate", label: "Select Candidate", icon: "select", itemType: "route", routeName: "/candidatefilter",  },
    
];

export const customerMatchingMenu: MenuItemType[] = [
    { value: "selectmatching", label: "Select Matching", icon: "select", itemType: "route", routeName: "/matchingfilter",  },
    { value: "editmatching", label: "Edit Matching", icon: "edit", itemType: "route", routeName: "/matchingedit" },
];

export const customerTodoMenu: MenuItemType[] = [
    { value: "selectmatching", label: "Select To-Do", icon: "select", itemType: "route", routeName: "/customertodo",  },
];

export const candidateMenu: MenuItemType[] = [
    { value: "selectcandidate", label: "Select Candidate", icon: "select", itemType: "route", routeName: "/candidatefilter",  },
    { value: "addcandidate", label: "Add Candidate", icon: "add", itemType: "route", routeName: "/candidateadd" },
    { value: "editcandidate", label: "Edit Candidate", icon: "edit", itemType: "route", routeName: "/candidateedit" },
    { value: "d1", label: "", itemType: "divider", routeName: "" },
    { value: "activate", label: "CV is visible", itemType: "switch", routeName: "" },
];
